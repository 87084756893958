@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Arial, Helvetica, sans-serif;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@layer base {
    :root {
    --background: 36 21% 94%; /* Light beige/tan background */
    --foreground: 0 0% 10%; /* Dark gray for text and content */
    --card: 36 21% 98%; /* Slightly lighter beige for cards */
    --card-foreground: 0 0% 10%; /* Dark gray for card text */
    --popover: 36 21% 98%; /* Matches card background */
    --popover-foreground: 0 0% 10%; /* Matches card text */
    --primary: 0 0% 20%; /* Updated primary color in HSL */
    --primary-hover: 10 73% 54%; /* Hover color (HSL for #D94E32) */
    --primary-foreground: 0 85.7% 97.3%; /* Light color for text on primary buttons */
    --secondary: 36 21% 95%; /* Lighter beige for secondary elements */
    --secondary-foreground: 0 0% 30%; /* Darker gray for secondary text */
    --muted: 36 21% 88%; /* Muted light beige */
    --muted-foreground: 0 0% 60%; /* Medium gray for muted text */
    --accent: 36 21% 94%; /* Accent matches background */
    --accent-foreground: 0 0% 10%; /* Dark text on accented elements */
    --destructive: 0 84.2% 60.2%; /* Red for destructive actions */
    --destructive-foreground: 0 0% 98%; /* Light text on destructive actions */
    --border: 0 0% 85%; /* Light gray for borders */
    --input: 0 0% 85%; /* Light gray for input backgrounds */
    --ring: 0 0% 10%; /* Dark gray for focus rings */
    --radius: 0.5rem; /* Consistent border-radius */
  }
    .dark {
    --background: 36 21% 94%; /* A light beige/tan color for the background */
    --foreground: 0 0% 10%; /* Dark gray for text and other content */
    --card: 36 21% 98%; /* Slightly lighter beige for the cards */
    --card-foreground: 0 0% 10%; /* Dark gray for the text inside the cards */
    --popover: 36 21% 98%; /* Matches card background for consistency */
    --popover-foreground: 0 0% 10%; /* Matches card text */
    --primary: 36 21% 30%; /* Darker beige for active elements */
    --primary-foreground: 0 0% 98%; /* Light color for text on primary buttons */
    --secondary: 36 21% 95%; /* Lighter beige/tan for secondary elements */
    --secondary-foreground: 0 0% 30%; /* Medium dark for text on secondary elements */
    --muted: 36 21% 88%; /* Muted light beige */
    --muted-foreground: 0 0% 60%; /* Medium gray for muted text */
    --accent: 36 21% 94%; /* Accent is close to the background for minimal contrast */
    --accent-foreground: 0 0% 10%; /* Darker text on accented elements */
    --destructive: 0 84.2% 60.2%; /* Red for destructive actions */
    --destructive-foreground: 0 0% 98%; /* Light text on destructive actions */
    --border: 0 0% 85%; /* Light gray for borders */
    --input: 0 0% 85%; /* Same as border for input backgrounds */
    --ring: 0 0% 10%; /* Dark gray for focus rings */
    --chart-1: 32 77% 60%; /* Gold for chart sections */
    --chart-2: 153 50% 41%; /* Greenish teal for chart sections */
    --chart-3: 200 37% 42%; /* Darker blue for chart sections */
    --chart-4: 43 84% 56%; /* Orange for chart sections */
    --chart-5: 0 64% 54%; /* Red for chart sections */
    --radius: 0.5rem; /* Consistent border radius */
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
